import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['data', 'options'],
  watch: {
    data() {
      this.$data._chart.destroy()
      this.renderChart(this.data, {
        borderWidth: '10px',
        hoverBackgroundColor: 'red',
        hoverBorderWidth: '10px',
        legend: {
          display: false
        }
      })
    }
  },
  mounted() {
    this.renderChart(this.data, {
      borderWidth: '10px',
      hoverBackgroundColor: 'red',
      hoverBorderWidth: '10px',
      legend: {
        display: false
      }
    })
  }
}
